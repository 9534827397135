import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import CheckoutForm from './CheckoutForm';
import PlanMessage from './PlanMessage';

interface Props {
  className?: string;
  setActiveStep: React.Dispatch<React.SetStateAction<string>>;
  uuid: string | null;
}

const StepPlanCard: React.FC<Props> = ({ className, setActiveStep, uuid }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [stripePromise, setStripePromise] = useState<any>(null);
  const [clientSecret, setClientSecret] = useState('');

  useEffect(() => {
    setStripePromise(loadStripe(process.env.REACT_APP_STRIPE_KEY!));
  }, []);

  useEffect(() => {
    if (!uuid) return;
    createSetupIntent();
  }, [uuid]);

  const createSetupIntent = () => {
    setLoading(true);
    axios
      .post(`${process.env.REACT_APP_API_URL}/v3/trials/stripe-setup-intent`, { uuid })
      .then(({ data }) => {
        setClientSecret(data.clientSecret);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className={className}>
      <div>
        <div className="plan-message">
          <PlanMessage />
        </div>
        {!loading && clientSecret && (
          <Elements stripe={stripePromise} options={{ clientSecret }}>
            <CheckoutForm setupLoading={loading} uuid={uuid} />
          </Elements>
        )}
      </div>
    </div>
  );
};

export default styled(StepPlanCard)``;
