import { Form, Input } from 'antd';
import { FormInstance } from 'antd/lib/form';
import { isEmpty } from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { IAddress } from '../types/address.model';
import PlaceAutocomplete from './PlaceAutocomplete';

interface Props {
  className?: string;
  form: FormInstance;
}

const PlaceFields: React.FC<Props> = ({ className, form }) => {
  const { t } = useTranslation(undefined, { useSuspense: false });
  const onAddressChange = (address: IAddress) => {
    console.log(address);
    if (isEmpty(address)) {
      form.setFieldsValue({
        address: null,
        city: null,
        zip: null,
        country: null,
        lng: null,
        lat: null,
      });
    } else {
      form.setFieldsValue({
        ...address,
        ['search-input']: address.fullAddress,
      });
    }
  };

  return (
    <div className={className}>
      <Form.Item label={t('STEP_3.FULL_ADDRESS')} name="search-input" rules={[{ required: true, max: 254 }]}>
        <PlaceAutocomplete onAddressChange={onAddressChange} />
      </Form.Item>
      <Form.Item name="address" label={t('GLOBAL.STREET')} rules={[{ required: true }]} style={{ display: 'none' }}>
        <Input disabled={true} />
      </Form.Item>
      <Form.Item name="city" label={t('GLOBAL.CITY')} style={{ display: 'none' }}>
        <Input disabled={true} />
      </Form.Item>
      <Form.Item name="zip" label={t('GLOBAL.ZIP')} style={{ display: 'none' }}>
        <Input disabled={true} />
      </Form.Item>
      <Form.Item name="country" label={t('GLOBAL.COUNTRY')} rules={[{ required: true }]} style={{ display: 'none' }}>
        <Input disabled={true} />
      </Form.Item>
      <Form.Item
        name="countryCode"
        label={t('GLOBAL.COUNTRY')}
        rules={[{ required: true }]}
        style={{ display: 'none' }}
      >
        <Input disabled={true} />
      </Form.Item>
      <Form.Item label="Longitude" name="lng" style={{ display: 'none' }}>
        <Input disabled={true} />
      </Form.Item>
      <Form.Item label="Latitude" name="lat" style={{ display: 'none' }}>
        <Input disabled={true} />
      </Form.Item>
    </div>
  );
};

export default styled(PlaceFields)``;
