import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import colors from '../styles/colors';

interface Props {
  className?: string;
}

const PlanMessage: React.FC<Props> = ({ className }) => {
  const { t } = useTranslation();
  return (
    <div className={className}>
      <p
        dangerouslySetInnerHTML={{
          __html: t('PLANS.PLAN_MESSAGE', { date: moment().add(14, 'days').format('L') }),
        }}
      />
    </div>
  );
};

export default styled(PlanMessage)`
  background-color: ${colors.greenLight};
  border-radius: 8px;
  padding: 15px;

  p {
    color: #000 !important;
    margin: 0;
  }
`;
