import { Button, Divider } from 'antd';
import axios from 'axios';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import colors from '../styles/colors';
import PlanMessage from './PlanMessage';

interface Props {
  className?: string;
  plans: any[];
  setActiveStep: React.Dispatch<React.SetStateAction<string>>;
  uuid: string | null;
}

const StepPlanDetails: React.FC<Props> = ({ className, plans, setActiveStep, uuid }) => {
  const { t } = useTranslation();
  const [period, setPeriod] = useState<'month' | 'year'>('month');
  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);
  const planId = searchParams.get('planId');

  const plan = plans.find((plan) => plan.id === planId);

  const onChangePeriod = (period: 'month' | 'year') => {
    setPeriod(period);
  };

  const price = plan ? (period == 'year' ? plan.price * 12 * 0.85 : plan.price) : 0;

  const onWantToPay = () => {
    axios.post(`${process.env.REACT_APP_API_URL}/v3/trials/change-unit`, { uuid, period });
    setActiveStep('plan_card');
  };

  if (!plan) return <></>;

  return (
    <div className={className}>
      <div className={plan.className}>
        <div className="title">
          {plan.headIcon}
          <div>
            <h4>{plan.title}</h4>
            <p>{plan.description}</p>
          </div>
        </div>
        <div className="switch">
          <span className={`period year ${period == 'year' ? 'active' : ''}`} onClick={() => onChangePeriod('year')}>
            {t('PLANS.ANNUAL')}
          </span>
          <span className={`period month ${period == 'month' ? 'active' : ''}`} onClick={() => onChangePeriod('month')}>
            {t('PLANS.MONTHLY')}
          </span>
        </div>
        <div className="options">
          <div className="option">
            <span>{plan.title}</span>
            <span>{formatPrice(price)}</span>
          </div>
          <div className="option">
            <span>{t('PLANS.VAT')}</span>
            <span>{formatPrice(0)}</span>
          </div>
        </div>
        <Divider />
        <div className="total-after">
          <div className="option">
            <span>{t('PLANS.TOTAL')}</span>
            <span>{formatPrice(price)}</span>
          </div>
        </div>
        <Divider />
        <div className="total-now">
          <div className="option">
            <span>{t('PLANS.TOTAL_TODAY')}</span>
            <span>{formatPrice(0)}</span>
          </div>
        </div>
        <Button type="primary" block onClick={onWantToPay}>
          {t('PLANS.ENTER_PAYMENT_DETAILS')}
        </Button>
        <div className="plan-message">
          <PlanMessage />
        </div>
        <p className="terms">{t('PLANS.TERMS')}</p>
      </div>
    </div>
  );
};

export default styled(StepPlanDetails)`
  .title {
    display: flex;
    align-items: flex-start;
    gap: 15px;

    h4 {
      margin: 0;
      font-size: 20px;
      font-weight: bold;
    }
  }

  .head-icon {
    font-size: 32px;
  }

  .clocking {
    .head-icon {
      color: ${colors.purpleDark};
    }

    .action {
      background-color: ${colors.purpleDark};
      border-color: ${colors.purpleDark};
    }
  }

  .planning {
    .head-icon {
      color: ${colors.blueBright};
    }

    .action {
      background-color: ${colors.blueBright};
      border-color: ${colors.blueBright};
    }
  }

  .full {
    .head-icon {
      color: ${colors.green};
    }

    .bottom {
      display: flex;
      align-items: center;
      gap: 15px;
    }
  }

  .switch {
    background-color: ${colors.greyLight};
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 5px;
    border-radius: 999px;
    gap: 15px;

    .period {
      flex: 1;
      border-radius: 999px;
      text-align: center;
      padding: 5px 0;
      font-weight: bold;
      cursor: pointer;

      &.active {
        background-color: #fff;
      }
    }
  }

  .options {
    color: ${colors.grey};
  }

  .total-now {
    font-weight: bold;
    margin-bottom: 25px;
  }

  .ant-divider-horizontal {
    margin: 16px 0;
  }

  .options,
  .total-after,
  .total-now {
    font-size: 16px;

    .option {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  .plan-message {
    margin-top: 25px;
  }

  .terms {
    font-size: 12px !important;
    text-align: center;
    margin-top: 10px;
  }
`;

const formatPrice = (number: number): string => {
  // Determine the currency symbol
  const currencySymbol =
    new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'EUR',
    })
      .formatToParts(1)
      .find((part) => part.type === 'currency')?.value || '€';

  // Format the number with spaces for thousands and commas for decimals
  const formattedNumber = new Intl.NumberFormat('fr-BE', {
    style: 'decimal',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })
    .format(Number(number))
    .replace(/\s/g, '.'); // Replace dot with comma for decimal separator

  return `${formattedNumber} ${currencySymbol}`;
};
