import { Button, Spin } from 'antd';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import TagManager from 'react-gtm-module';
import { useTranslation } from 'react-i18next';
import { FaCheck } from 'react-icons/fa';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import colors from '../styles/colors';

interface Props {
  className?: string;
  plans: any[];
  setActiveStep: React.Dispatch<React.SetStateAction<string>>;
  uuid: string | null;
}

const StepPlans: React.FC<Props> = ({ className, plans, setActiveStep, uuid }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!uuid) return;
    confirmEmail();
  }, [uuid]);

  const confirmEmail = () => {
    setLoading(true);
    axios.post(`${process.env.REACT_APP_API_URL}/v3/trials/confirm-account`, { uuid }).then(() => {
      axios
        .get(`${process.env.REACT_APP_API_URL}/v3/trials/account`, { params: { uuid } })
        .then(({ data }) => {
          if (data.account_id && data.confirmed_at && data.card_added_at) {
            setActiveStep('already_confirmed');
          } else {
            TagManager.dataLayer({
              dataLayer: {
                event: 'lead_confirmed',
              },
              dataLayerName: 'PageDataLayer',
            });
          }
        })
        .finally(() => {
          setLoading(false);
        });
    });
  };

  const onPlanSelected = (planId: string) => {
    history.push({
      pathname: history.location.pathname,
      search: `?planId=${planId}&uuid=${uuid}`,
    });
    axios.post(`${process.env.REACT_APP_API_URL}/v3/trials/change-plan`, { uuid, planId });
    setActiveStep('plan_details');
  };

  if (loading) {
    return <Spin style={{ justifyContent: 'center' }} />;
  }

  return (
    <div className={className}>
      <h1>{t('PLANS.CHOOSE_PLAN')}</h1>
      <div className="plans">
        {plans.map((plan) => (
          <div key={plan.id} className={`plan ${plan.className}`}>
            <div className="title">
              {plan.headIcon}
              <h4>{plan.title}</h4>
            </div>
            <div className="description">
              <p>{plan.description}</p>
            </div>
            <div className="bottom">
              <Button className="action" type="primary" size="middle" onClick={() => onPlanSelected(plan.id)}>
                {t('PLANS.I_CHOOSE_THIS')}
              </Button>
              <div className="options">
                {plan.options.map((option: any) => (
                  <div key={option} className="option">
                    <FaCheck className="icon" />
                    <span>{option}</span>
                  </div>
                ))}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default styled(StepPlans)`
  .plans {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;

    .plan {
      background-color: ${colors.blueExtraLight};
      padding: 25px;
      border-radius: 10px;

      .title {
        display: flex;
        align-items: center;
        gap: 15px;

        h4 {
          margin: 0;
          font-size: 20px;
          font-weight: bold;
        }
      }

      .description {
        margin: 15px 0;

        p {
          margin: 0;
          font-size: 11px;
          color: #000;
        }
      }

      .action {
        border-radius: 8px;
        width: 100%;
        padding: 8px;
      }

      .options {
        display: flex;
        flex-direction: column;
        gap: 5px;
        font-size: 11px;
        color: ${colors.grey};
        margin-top: 10px;

        .option {
          display: flex;
          align-items: flex-start;
          gap: 5px;

          .icon {
            font-size: 18px;
          }
        }
      }

      .head-icon {
        font-size: 32px;
      }
    }

    .clocking {
      .head-icon {
        color: ${colors.purpleDark};
      }

      .action {
        background-color: ${colors.purpleDark};
        border-color: ${colors.purpleDark};
      }
    }

    .planning {
      .head-icon {
        color: ${colors.blueBright};
      }

      .action {
        background-color: ${colors.blueBright};
        border-color: ${colors.blueBright};
      }
    }

    .full {
      .head-icon {
        color: ${colors.green};
      }

      .bottom {
        display: flex;
        align-items: center;
        gap: 15px;
      }
    }

    .full {
      grid-column: span 2;
    }
  }
`;
