import { Button } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

interface Props {
  className?: string;
}

const StepAlreadyConfirmed: React.FC<Props> = ({ className }) => {
  const { t } = useTranslation(undefined, { useSuspense: true });

  return (
    <div className={className}>
      <h1>{t('STEP_ACCOUNT_ALREADY_EXISTS.TITLE')}</h1>
      <p>{t('STEP_ACCOUNT_ALREADY_EXISTS.DESCRIPTION')}</p>
      <Button
        type="primary"
        onClick={() => {
          window.location.href = `${process.env.REACT_APP_SHYFTER_APP_URL}/login`;
        }}
      >
        {t('STEP_ACCOUNT_ALREADY_EXISTS.LOGIN_BUTTON')}
      </Button>
      <p style={{ marginTop: 20 }}>
        {t('STEP_ACCOUNT_ALREADY_EXISTS.FORGOT_PASSWORD')}
        <br />
        <a href={`${process.env.REACT_APP_SHYFTER_APP_URL}/forgot-password`}>
          {t('STEP_ACCOUNT_ALREADY_EXISTS.FORGOT_PASSWORD_LINK')}
        </a>
      </p>
      <div className="contact">
        <b>{t('STEP_ACCOUNT_ALREADY_EXISTS.CONTACT')}</b>
        <br />
        <a href="https://shyfter.co/fr/contact/">{t('STEP_ACCOUNT_ALREADY_EXISTS.CONTACT_LINK')}</a>
      </div>
    </div>
  );
};

export default styled(StepAlreadyConfirmed)`
  .contact {
    margin-top: 40px;
    background: #f7f8fb;
    border: 1px solid #d8d8d8;
    padding: 20px 30px;
    b {
      color: #7f8894;
    }
  }
`;
