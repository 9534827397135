import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { Button } from 'antd';
import axios from 'axios';
import React, { useState } from 'react';
import TagManager from 'react-gtm-module';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import colors from '../styles/colors';

interface Props {
  className?: string;
  setupLoading: boolean;
  style?: any;
  return_url?: string;
  uuid: string | null;
}

const CheckoutForm: React.FC<Props> = ({ className, setupLoading, style = {}, return_url, uuid }) => {
  const { t } = useTranslation();
  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  const onClick = async () => {
    if (!stripe || !elements) {
      return;
    }

    setLoading(true);

    await axios.post(`${process.env.REACT_APP_API_URL}/v3/trials/create-account`, { uuid }).then(() => {
      TagManager.dataLayer({
        dataLayer: {
          event: 'lead_won',
        },
        dataLayerName: 'PageDataLayer',
      });
    });

    const { error } = await stripe.confirmSetup({
      elements,
      confirmParams: {
        return_url: process.env.REACT_APP_SHYFTER_APP_URL + '/login?trial=true',
      },
    });

    if (error.type === 'card_error' || error.type === 'validation_error' || error.type == 'invalid_request_error') {
      setMessage(error.message || 'An unexpected error occured.');
    } else {
      setMessage('An unexpected error occured.');
    }

    setLoading(false);
  };

  return (
    <div style={{ backgroundColor: '#FFF', padding: '25px 0', borderRadius: 8, ...style }}>
      {message && (
        <div id="payment-message" style={{ marginBottom: 15, color: colors.red, fontSize: 14 }}>
          {message}
        </div>
      )}
      {!setupLoading && (
        <>
          <PaymentElement />
          <Button type="primary" block size="large" style={{ marginTop: 25 }} onClick={onClick}>
            {loading ? t('LOADING') : t('START_NOW')}
          </Button>
        </>
      )}
    </div>
  );
};

export default styled(CheckoutForm)``;
